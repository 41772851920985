<template>
  <div>
    <van-cell-group title="筛选">
      <van-search v-model="sea.KEY" placeholder="请输入搜索关键字"  @search="filter" @clear="filter"/>
      <van-cell title="日期" :value="date.val" @click="date.show=true" is-link/>
      <van-dropdown-menu>
        <van-dropdown-item v-model="sea.STA" @change="filter" :options="[
            {text:'全部状态', value:0},
            {text:'借出', value:1},
            {text:'已还', value:2},
        ]" />
      </van-dropdown-menu>
      <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true" />
    </van-cell-group>
    <van-divider />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
      <van-cell v-for="item in lst.da" :key="item.ID" :title="item.NAME" :value="item.CNT">
        <template #label>
          <p>{{item.DESCRIPTION}}</p>
          <p>
            {{item.EVENT_DT | datetime('YYYY-MM-DD')}}
            <span v-if="item.RETURN_DT" style="color:#ee0a24">/ {{item.RETURN_DT | datetime('YYYY-MM-DD')}}</span>
          </p>
        </template>
        <template #title>
          {{item.NAME}}
          <span style="color: #999">[{{item.STA_TXT}}]</span>
        </template>
        <template #right-icon>
          <van-button type="primary" size="mini" style="margin-left: 10px" v-if="item.STA==1" @click="revert(item.ID)">归还</van-button>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import moment from "moment";
import {Dialog} from "vant";
export default {
  name: "index",
  data() {
    return {
      sea:{
        PAGE_SIZE:10,
        PAGE_INDEX:-1,
        KEY:"",
        STA:0,
      },
      lst:{
        da:[],
        loading:false,
        finish:false
      },
      date:{
        show:false,
        val:""
      }
    }
  },
  created() {

  },
  methods:{
    filter(){
      this.lst.da.length=0;
      this.lst.finish=false;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/UTL/RentApi/Getlist",
        data:this.sea,
        completed(its,n){
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    },
    dateSel(d){
      this.sea.DTS=moment(d[0]).format('YYYY-MM-DD');
      this.sea.DTE=moment(d[1]).format('YYYY-MM-DD')
      this.date.show=false;
      this.date.val=this.sea.DTS+' 至 '+this.sea.DTE;
      this.filter();
    },
    revert(id){
      let self=this;
      Dialog.confirm({
        title:"真的要归还吗？",
      }).then(()=>{
        this.whale.remote.getResult({
          url:"/api/Mobile/UTL/RentApi/Revert",
          data: {ID:id},
          completed(){
            self.filter();
          }
        })
      }).catch(()=>{})
    }
  }
}
</script>

